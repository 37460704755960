import * as React from "react";
import { Typography,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar, } 
from "@mui/material/";
import Grid from "@mui/material/Grid";

import image1 from "../../assets/shop/berg.jpg";
import image2 from "../../assets/shop/Thiago.jpeg";
//import image3 from "../../assets/shop/Helder.jpg";
import image4 from "../../assets/shop/Laerte.jpg";
//import image5 from "../../assets/shop/fotopaulo.jpg";
import image6 from "../../assets/shop/Diogo.jpg";
import image7 from "../../assets/shop/FrenteBarbeariaEscura.png";
import image8 from "../../assets/shop/Rhuty.jpg"

const Equipe = () => {
  return (
    <React.Fragment>
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <div
          style={{
            backgroundColor: "#1C1C1C",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: 60,
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: 32,
              margin: 0,
              marginTop: 60,
              fontWeight: "bold",
              alignSelf: "flex-start",
            }}
          >
            Nossa Equipe
          </p>
          <Divider
            style={{
              backgroundColor: "#ECB729",
              width: "100%",
              borderBottomWidth: 5,
              alignSelf: "flex-start",
            }}
          />
          <p
            style={{
              color: "white",
              fontSize: 28,
            }}
          >
          ⁠"⁠Quando uma equipa abraça a diversidade e reconhece o valor de cada membro,
          criando um ambiente de respeito e confiança mútua, as pessoas se sentem mais
          envolvidas e motivadas a contribuir com suas habilidades únicas,
          gerando uma sinergia poderosa que impulsiona a equipa rumo ao sucesso.
          <h5>

          </h5>
          
                 Anderson C. Nascimento
          </p>

        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <ImageListItem key={image7} style={{ height: "100%" }}>
          <img
            src={image7}
            alt={{ color: "#ddd" }}
             style={{
             objectFit: "cover",
            }}
          />
          <ImageListItemBar
            style={{
              background:
                "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
              color: "#ddd",
            }}
          />
        </ImageListItem>
      </Grid>
    </Grid>
    <div style={{ backgroundColor: "#211F20" }}>
      <div style={{ paddingTop: 50, paddingRight: 50, paddingLeft: 50 }}>
        <Typography
          fontSize={{
            lg: 50,
            md: 45,
            sm: 40,
            xs: 35,
          }}
          style={{
            fontFamily: "Helvetica",
            lineHeight: "1em",
            fontWeight: "bold",
            color: "#F8F4F3",
          }}
          gutterBottom
        >
          Conheça Nossa Equipe
         </Typography>
        <Divider
          style={{
            backgroundColor: "#F3B627",
            width: "9%",
            borderBottomWidth: 5,
          }}
        />
        <Divider style={{ backgroundColor: "#F3B627" }} />
      </div>
      <SingleLineImageList />
    </div>
  </React.Fragment>
);
};

export default Equipe;
function SingleLineImageList() {
  const itemData = [
    {
      img: image1,
      title: "Image 1",
      author: "author",
      barber:"Barbeiro Berg",
      unid: "Und. Manaíra"
        
    },
   
    {
      img: image2,
      title: "Image 2",
      author: "author",
      barber:"Barbeiro Thiago",
      unid: "Und. Manaíra"
    },
    {
      img: image4,
      title: "Image 4",
      author: "author",
      barber:"Barbeiro Laertte",
      unid: "Und. Manaíra"
    },
    {
      img: image6,
      title: "Image 5",
      author: "author",
      barber:"Barbeiro Diogo",
      unid: "Und. Manaíra"
      
    },
   
  
    {
      img: image8,
      title:"Image 5",
      author: "author",
      barber: "Barbeiro Ruthyerre",
      unid: "Unid. Bancários"
    }
  ];

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: "transparent",
      }}
     
    >

        
      <ImageList
        style={{
          
          flexWrap: "nowrap",
          transform: "translateZ(0)",
        }}
         cols={2}
      >
        <ImageListItemBar
          
          style={{
            background:
            "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
            color: "#ddd"
            
          }}
          
          />
        
        {itemData.map((item) => (
          
             <ImageListItem key={item.img} 
             style={{padding:10}}>
            <img src={item.img} alt={{ color: "#ddd"}}/>
                      
            <div style={{borderRadius: "02px",  background:
                  "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                color: "#ddd",
             }}>

            <h1 style={{color:"white",
                marginTop:10,
                fontSize:25,
                textAlign:"center"
               }}>{item.barber}</h1>

              <h2 style={{color:"white",
                fontSize:20,
                textAlign:"center"
                }}>{item.unid}</h2>
            </div>
            
                      
          </ImageListItem>
      
          
        ))}
          
      </ImageList>
    </div>
  );
}
