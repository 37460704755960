import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./containers/Footer";
import Layout from "./containers/NavBar";
import Home from "./pages/Home/index";
import Clube from "./pages/Clube/index";
import Equipe from "./pages/Equipe/index"
import withRoot from "./withRoot";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Sobre from "./pages/Sobre";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";



const App = () => {
  useEffect(()=>{
    const tagManagerArgs = {
      gtmId:'AW-469910291'
    }
    TagManager.initialize(tagManagerArgs)
  },[])
  return (
    
    
    <Router>
      <Layout />
      <Routes>
      
        <Route exact path="/" element={<Home />} />
        <Route exact path="/clube" element={<Clube />} />
        <Route exact path="/equipe" element={<Equipe />} /> 
        <Route exact path="/sobre" element={<Sobre />} />
        <Route path="*" element={<div />} />
      </Routes>
      <a
      
        href="https://api.whatsapp.com/send?phone=+5583981800849&text=Olá! gostaria de fazer um agendamento!"
        class="float"
        target="_blank"
        rel="noopener noreferrer"
      >
      
        <i class="my-float">
          <WhatsAppIcon style={{ fontSize: 55 }} />
        </i>
      </a>
      <Footer />
      
    </Router>
    
  );
};


export default withRoot(App);
