import * as React from "react";
import Grid from "@mui/material/Grid";
import { Divider, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import clube from "../../assets/morenoPensativo.png";
import clube2 from "../../assets/clube_chefe3.jpg";
import assinatura from "../../assets/assinatura.png";
import ilimitado from "../../assets/ilimitado.jpg";
import CheckIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "reactstrap";

const Clube = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              background: "rgb(255,226,0)",
              background: "linear-gradient(327deg, rgba(255,188,0,1) 26%, rgba(255,169,0,1) 46%, rgba(255,140,0,1) 100%)",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%", 
              alignItems: "center",
              justifyContent: "center",
              padding: 50,
            }}
           >
          
            
               <Typography
              variant="h3"
              component="h3"
              color="#fff"
              textAlign="center"
              padding={15}
              
              
            >
          Eii!!! você Já pensou em cortar o cabelo e fazer a barba 
            sempre que quiser pagando 
            apenas um valor mensal?{" "}
            
            </Typography>
            <img
              alt="assinatura"
              src={assinatura}
              style={{
                width: 300,
                height: 300,
              }}
            />
              
          <img
            alt="clube"
            src={clube}
            style={{ height: "100%", width: "100%",objectFit: "cover" }}
          />         
            
             
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
       
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "#18181A",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "1%",
        }}
      >
        
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <img
            alt="ilimitado"
            src={ilimitado}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <div
            style={{
              backgroundColor: "#57099D",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
                fontSize: 32,
                margin: 0,
                padding: 10,
                marginTop: 60,
              }}
            >
              O clube funciona como uma Netflix, que você paga um valor fixo e
              faz o corte, barba ou os dois quantas vezes quiser.
            </p>
            <p
              style={{
                color: "#FCBB17",
                fontSize: 32,
                padding: 10,
                fontWeight: "bold",
                margin: 0,
              }}
            >
              O seu cuidado pessoal deve ser diferente?
            </p>
            <Divider
              style={{
                backgroundColor: "#F3B627",
                width: "80%",
                borderBottomWidth: 2,
              }}
            />
            <p
              style={{
                color: "white",
                fontSize: 18,
                padding: 10,
                margin: 0,
                fontWeight: "bold",
              }}
            >
              Ser assinante do clube não garante só o corte e barba ilimitados,
              mas vários benefícios:
            </p>
            <ul>
              <li
                style={{
                  color: "white",
                  fontSize: 18,
                  padding: 10,
                  margin: 30,
                }}
              >
                10% de desconto em produtos e serviços da Barbearia ELCHEFE BARBERSHOP.
              </li>
              <li
                style={{
                  color: "white",
                  fontSize: 18,
                  padding: 10,
                  margin: 30,
                }}
              >
                Presentes exclusivos que só quem é assinante do clube pode ter.{" "}
              </li>
              <li
                style={{
                  color: "white",
                  fontSize: 18,
                  padding: 10,
                  margin: 30,
                }}
              >
                Benefícios com empresas parceiras.
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "#242323",
          paddingTop: 40,
          paddingBottom: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        
        <Grid container>
          
         
          <Grid xs={12} sm={6} md={6} lg={4}>
            <BasicCard
              nome="Corte Ilimitado R$89,90"
              lista={[
                
                "Corte Ilimitado",
                "Pezinho",
                "10% desconto em Produtos",
                "Café Expresso Grátis",
                "10% de Desconto em outros Serviços",
                "Brindes Exclusivos"
              ]}
              redirectUrl="https://app.galaxpay.com.br/landingpage1976508/clubechefe02/assine-agora/corte-ilimitado/29"
              backgroundColor="#FFA500"
              border
            />
            
          </Grid>

          <Grid xs={12} sm={6} md={6} lg={4}>
            <BasicCard
              nome="Cabelo e Barba R$159,90"
              lista={[
                "Corte Ilimitado",
                "Barba Ilimitado",
                "Pezinho",
                "Presentes Exclusivos",
                "10% desconto em Produtos",
                "Café Expresso Grátis",
                "10% de Desconto em outros Serviços",
                "Convidado Vip Mês Aniversário",
                "Brindes Exclusivos",
                "Descontos Especiais em Cervejas e Energéticos",
              ]}
              redirectUrl="https://app.galaxpay.com.br/landingpage1976508/clubechefe02/assine-agora/combo/28"
              backgroundColor="#008080"
              border              
            />
          </Grid>

          <Grid xs={12} sm={6} md={6} lg={4}>
            <BasicCard
              nome="Barba Ilimitado R$S119,90"
              lista={[
                "Barba Ilimitado",
                "10% desconto em Produtos",
                "Café Expresso Grátis",
                "10% de Desconto em outros Serviços",
                "Brindes Exclusivos",
              ]}
              redirectUrl="https://app.galaxpay.com.br/landingpage1976508/clubechefe02/assine-agora/barba-ilimitado/31"
              backgroundColor="#FF8C00"
              border
            />
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <img
            alt="clube2"
            src={clube2}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <div
            style={{
              backgroundColor: "#57099D",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingLeft: 30,
              paddingBottom: 30,
            }}
          >
            <p style={{ padding: 10, color: "#f3b627", fontSize: 28 }}>
              A assinatura é descontada no seu cartão mensalmente, mas sem
              comprometer o seu limite.
            </p>
            <h5
              style={{
                color: "white",
                fontSize: 52,
                paddingLeft: 10,
              }}
            >
              Legal né? Agora é só encontrar o plano que combina com
              você e Assinar.
            </h5>
            <Button
              onClick={() =>
                window.open("https://app.galaxpay.com.br/landingpage1976508/clubechefe02", "_blank")
              }
              size="large"
              variant="contained"
              style={{
                color: "white",
                background: "linear-gradient(327deg, rgba(255,188,0,1) 26%, rgba(255,169,0,1) 46%, rgba(255,140,0,1) 80%)",
                borderRadius: 50,
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 10,
                paddingBottom: 10,
                fontSize: 17,
              }}
            >
              ASSINAR AGORA
            </Button>
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "#000000",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: 20,
        }}
      >
        <h2 style={{ color: "#fcb91b", fontSize: 28, marginTop: 50 }}>
          Perguntas frequentes
        </h2>
        <h6
          style={{ color: "#fcb91b", fontSize: 22, margin: 0, marginTop: 20 }}
        >
          Como funciona?
        </h6>
        <p style={{ color: "#fff", fontSize: 20 }}>
          O CLUBE DO CHEFE é o primeiro clube de assinatura recorrente para
          barbearias da Paraíba em João Pessoa. 
           Um plano mensal que te permite cortar o
          cabelo e fazer a barba ILIMITADO. Sempre que quiser.
        </p>
        <h6
          style={{ color: "#fcb91b", fontSize: 22, margin: 0, marginTop: 20 }}
        >
          Possui algum tempo de limite de uso?
        </h6>
        <p style={{ color: "#fff", fontSize: 20 }}>
          É ILIMITADO! Quer cortar o cabelo e fazer a barba todo dia? Toda
          semana? Quinzenalmente? Você escolhe.
        </p>
        <h6
          style={{ color: "#fcb91b", fontSize: 22, margin: 0, marginTop: 20 }}
        >
          Possui taxa de adesão ou fidelidade?
        </h6>
        <p style={{ color: "#fff", fontSize: 20 }}>
          Sem cobranças adicionais. Você pode entrar e sair quando quiser e sem
          pagar nem um centavo a mais por isso.{" "}
        </p>
        <h6
          style={{ color: "#fcb91b", fontSize: 22, margin: 0, marginTop: 20 }}
        >
          A assinatura é feita somente pelo site?
        </h6>
        <p style={{ color: "#fff", fontSize: 20 }}>
          NÃO. Se preferir você pode assinar o seu clube em qualquer unidade 
          EL CHEFE BARBERSHOP.{" "}
        </p>
        <h6
          style={{ color: "#fcb91b", fontSize: 22, margin: 0, marginTop: 20 }}
        >
          Quais são as formas de pagamento?
        </h6>
        <p style={{ color: "#fff", fontSize: 20 }}>
          O pagamento da assinatura é realizado no cartão de crédito e
          sem comprometer o seu limite ou PIX.{" "}
        </p>
        <h6
          style={{ color: "#fcb91b", fontSize: 22, margin: 0, marginTop: 20 }}
        >
          Meu filho pode utilizar a minha assinatura?
        </h6>
        <p style={{ color: "#fff", fontSize: 20 }}>
          Não. A assinatura é individual.{" "}
        </p>
        <h6
          style={{ color: "#fcb91b", fontSize: 22, margin: 0, marginTop: 20 }}
        >
          Participantes do clube são atendidos pelo Dinho?
        </h6>
        <p style={{ color: "#fff", fontSize: 20 }}>
          Não. Os assinantes do clube são atendidos pela equipe de barbeiros.
        </p>
      </div>
      <Grid
        container
        style={{ justifyContent: "center", backgroundColor: "#6d11be" }}
      >
        
      </Grid>
    </div>
  );
};

const BasicCard = ({
  nome,
  lista = [],
  redirectUrl = "",
  backgroundColor,
  border = false,
}) => {
  return (
    <Card
      style={{
        backgroundColor,
        marginLeft: 20,
        marginRight: 20,
        height: "100%",
        border: "2px solid",
        borderColor: border ? "#FCBB17" : "#FFF",
      }}
    >
      <CardContent style={{}}>
        <h2 style={{ color: "white" }}>{nome}</h2>
        {vantagensLista.map((item) => (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                width: 28,
                height: 28,
                borderRadius: 14,
              }}
            >
              {lista.includes(item) ? (
                <CheckIcon style={{ color: "#00FF00" }} />
              ) : (
                <CancelIcon style={{ color: "#FFF" }} />
              )}
            </div>
            <p
              style={{
                color: "white",
                marginTop: 0,
                marginLeft: 2,
              }}
            >
              {item}
            </p>
          </div>
        ))}
      </CardContent>
      <CardActions style={{ justifyContent: "center", padding: 20 }}>
        <Button className="btn btn-outline-info"
          onClick={() => window.open(redirectUrl, "_blank")}
          size="small"
          variant="outlined"
          style={{
            color: "white",

            borderColor: "white",
            borderRadius: 50,
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 17,
          }}
        >
         Assine Agora
        </Button>
      </CardActions>
    </Card>
  );
};

const vantagensLista = [
  "Direito a Dois Cortes de Cabelo",
  "Corte de Cabelo de Segunda à Quarta-Feira",
  "Corte Ilimitado",
  "Barba Ilimitado",
  "Pezinho",
  "10% desconto em Produtos",
  "Café Expresso Grátis",
  "Convidado Vip Mês Aniversário",
  "10% de Desconto em outros Serviços",
  "Brindes Exclusivos",
  "Descontos Especiais em Cervejas e Energéticos",
];

export default Clube;
