import React, { useState } from "react";
import {
  Modal,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function DrawerComponent(props) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const direitoLink = (path) => {
    return {
      fontSize: 16,
      color: path === props.pathname ? "secondary.main" : "common.white",
      ml: 3,
    };
  };
  return (
    <>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.9)",
        }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                href="/#/"
                sx={direitoLink("/")}
              >
                {"HOME"}
              </Link>
            </ListItemText>
          </ListItem>
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Link
                      color="inherit"
                      variant="h6"
                      underline="none"
                      href="/#/clube"
                      sx={direitoLink("/clube")}
                    >
                      {"PLANOS ASSINATURA"}
                    </Link>
                  </ListItemText>
                </ListItem>
           <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                href="/#/equipe"
                sx={direitoLink("/equipe")}
              >
                {"EQUIPE"}
              </Link>
            </ListItemText>
          </ListItem> 
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    color="inherit"
                    variant="h6"
                    underline="none"
                    href="/#/sobre"
                    sx={direitoLink("/sobre")}
                  >
                    {"SOBRE"}
                  </Link>
                </ListItemText>
              </ListItem>
        </List>
      </Modal>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon style={{ color: "#f3b627", fontSize: 34 }} />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
