import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import elchefe from "../assets/logo3.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function Footer() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex",  background: "linear-gradient(327deg, rgba(255,188,0,1) 26%, rgba(255,169,0,1) 46%, rgba(255,140,0,1) 80%)", margin: 0 }}
    >
      <Container sx={{ my: 1, display: "flex" }}>
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="elchefe"
              src={elchefe}
              style={{
                width: 172,
                height: 80,
                
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginBottom: 30,
                justifyContent: "space-evenly",
                display: "flex",
                width: 172,
              }}
            >
              <InstagramIcon
                sx={{ color: "#fff" }}
                onClick={() =>
                  window.open("https://www.instagram.com/elchefebarbershop/")
                }
              />
              <FacebookIcon
                sx={{ color: "#fff" }}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/BarbeariaElchefeBarbershop/"
                  )
                }
              />
              <WhatsAppIcon
                sx={{ color: "#fff" }}
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=5583981800849"
                  )
                }
              />
            </div>
            <p style={{ color: "white", fontSize: 16, margin: 0 }}>
              Segunda a Sexta - 09h às 20h
            </p>
            <p style={{ color: "white", fontSize: 16, margin: 0 }}>
              Sábados - 08h às 18h
            </p>
           
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={3}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          
            <h6 style={{ color: "white", fontSize: 20, marginBottom: 0 }}>
              Unidade Manaíra
            </h6>
            <p style={{ color: "white", fontSize: 16, marginBottom: 0 }}>
              Rua João Câncio, 116 - Sala 103
            </p>
            <p style={{ color: "white", fontSize: 16, marginTop: 0 }}>
               Tel: (83) 9 98180-0849
            </p>
           
            
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          
            <h6 style={{ color: "white", fontSize: 20, marginBottom: 0 }}>
              Unidade Bancários 
            </h6>
            <p style={{ color: "white", fontSize: 16, marginBottom: 0 }}>
              Avenida Walfredo Brandão, 1060 - Sala 104
            </p>
            <p style={{ color: "white", fontSize: 16, marginTop: 0 }}>
               Tel: (83) 9 99628-0240
            </p>
           
            
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
