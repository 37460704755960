import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import { useTheme, useMediaQuery } from "@mui/material";
import logo from "../assets/logo.png";
import { useLocation } from "react-router-dom";
import DrawerComponent from "../components/Drawer";
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const direitoLink = (path) => {
  return {
    fontSize: 16,
    color: path === useLocation().pathname ? "secondary.main" : "common.white",
  };
};

const AppAppBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <AppBar position="sticky">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ flex: 1, justifyContent: "center" }}>
            <Link
              variant="h6"
              underline="none"
              color="inherit"
              href="/"
              sx={{ fontSize: 24 }}
            >
              <img src={logo} alt="logo" style={{ width: 180 }} />
            </Link>
          </Box>

          {isMobile ? (
            <DrawerComponent pathname={useLocation().pathname} />
          ) : (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                href="/#/"
                sx={direitoLink("/")}
              >
                {"HOME"}
              </Link>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                href="/#/clube"
                sx={direitoLink("/clube")}
              >
                {"PLANOS ASSINATURA"}
              </Link>
               
                <Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  href="/#/sobre"
                  sx={direitoLink("/sobre")}
                >
                  {"SOBRE"}
                </Link>
              <Button color="" className="btn btn-outline-warning"
               
                size="medium"
                variant="contained"
                component="a"
                onClick={() =>
                  window.open("https://elchefe.bemp.online/", "_blank")
                }
                sx={{ ...direitoLink, color: "common.white" }}
              >
                AGENDE
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AppAppBar;
