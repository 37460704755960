import * as React from "react";
import {
  Typography,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import image1 from "../../assets/shop/BarbeariaElchefe2.jpg";
import image2 from "../../assets/shop/BarbeariaElchefe_.jpg";
import image3 from "../../assets/shop/BarbeariaElchefe4.jpg";
import image4 from "../../assets/shop/BarbeariaElchefe.jpg";
import image5 from "../../assets/shop/clienteBerg.jpg";
import image6 from "../../assets/shop/6.png";

const Sobre = () => {
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <div
            style={{
              backgroundColor: "#1C1C1C",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              padding: 40,
            }}
          >
            <p
              style={{
                color: "white",
                fontSize: 32,
                margin: 0,
                marginTop: 60,
                fontWeight: "bold",
                alignSelf: "flex-start",
              }}
            >
              Sobre
            </p>
            <Divider
              style={{
                backgroundColor: "#ECB729",
                width: "50%",
                borderBottomWidth: 3,
                alignSelf: "flex-start",
              }}
            />
            <p
              style={{
                color: "white",
                fontSize: 18,
              }}
            >
              Na melhor barbearia, a Barbearia El chefe o homem pode cortar o
              cabelo em ambiente personalizado, só para o público masculino, com
              atendimento diferenciado, toalha quente, navalha, café,
              recepcionista, marcação de horário por aplicativo, link ou
              whatsapp. Uma barbearia realmente com a cara do Chefe.
            </p>
            <p
              style={{
                color: "white",
                fontSize: 18,
              }}
            >
              Com o objetivo de resgatar esse passado, atentando para o retorno
              da clássica elegância masculina e os cuidados com a aparência, foi
              inaugurada a Barbearia El Chefe no dia 31/11/2020 que atualmente
              conta com sua unidade na principal região de João Pessoa, trazendo
              também conceitos de requinte e inovação da atualidade como por
              exemplo a Barbearia por Assinatura, onde o cliente escolhe um
              plano mensal e realiza serviços Ilimitados.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <ImageListItem key={image6} style={{ height: "100%" }}>
            <img
              src={image6}
              alt={{ color: "#ddd" }}
              style={{
                objectFit: "cover",
              }}
            />
            <ImageListItemBar
              style={{
                background:
                  "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                color: "#ddd",
              }}
            />
          </ImageListItem>
        </Grid>
      </Grid>
      <div style={{ backgroundColor: "#211F20" }}>
        <div style={{ paddingTop: 50, paddingRight: 50, paddingLeft: 50 }}>
          <Typography
            fontSize={{
              lg: 40,
              md: 35,
              sm: 30,
              xs: 25,
            }}
            style={{
              fontFamily: "Helvetica",
              lineHeight: "1em",
              fontWeight: "bold",
              color: "#F8F4F3",
            }}
            gutterBottom
          >
            Barbearia em Manaíra
          </Typography>
          <Divider
            style={{
              backgroundColor: "#F3B627",
              width: "39%",
              borderBottomWidth: 5,
            }}
          />
          <Divider style={{ backgroundColor: "#F3B627" }} />
        </div>
        <SingleLineImageList />
      </div>
    </React.Fragment>
  );
};

export default Sobre;

function SingleLineImageList() {
  const itemData = [
    {
      img: image1,
      title: "Image 1",
      author: "author",
    },
    {
      img: image2,
      title: "Image 2",
      author: "author",
    },
    {
      img: image3,
      title: "Image 3",
      author: "author",
    },
    {
      img: image4,
      title: "Image 4",
      author: "author",
    },
    {
      img: image5,
      title: "Image 5",
      author: "author",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: "transparent",
      }}
    >
      <ImageList
        style={{
          flexWrap: "nowrap",
          transform: "translateZ(0)",
        }}
        cols={5}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img src={item.img} alt={{ color: "#ddd" }} />
            <ImageListItemBar
              // title={{ color: "#ddd" }}
              style={{
                background:
                  "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                color: "#ddd",
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
