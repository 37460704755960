import * as React from "react";
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


import {
  
  Card,
  CardActions,
  CardContent,
  Typography,
  Divider,
} from "@mui/material/";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import maoCelular from "../../assets/Segurando_celular.jpeg";



const Home = () => {
  return (
    <React.Fragment>
    
      <Card
        style={{
          backgroundImage: `url(${require("../../assets/FrenteBarbeariaEscura.png")})`,
          backgroundPosition: "center",
          padding: "100px 40px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: 0,
          
          
        }}
      >
        <CardContent
          style={{
            color: "white",
            
            
            
          }}
        >
          <Typography
            fontSize={{
              lg: 80,
              md: 75,
              sm: 55,
              xs: 43,
            }}
            style={{
              fontFamily: "Helvetica",
              lineHeight: "1em",
              fontWeight: "bold",
              
              
            }}
            gutterBottom
          >
            Aqui Você   <br />
            EL CHEFE.
          </Typography>
          <Typography
            fontSize={{
              lg: 20,
              md: 23,
              sm: 25,
              xs: 20,
            }}
            style={{
              fontWeight: "bold",
              color: "#DAA520",
              wordBreak: "normal"
            }}
          >
          
            <h1 style={{paddingTop: 10, paddingRight: 10, paddingLeft: 10}}>
               Um espaço criado para te oferecer uma
               experiência única.{" "}
            </h1>

            
            <br />
            <span
              style={{
                fontWeight: "bold", paddingLeft:10,
                color: "#fff",
                fontSize:34
              }}
            >
              Agende Aqui!
            </span>
          </Typography>
        </CardContent>
        <CardActions style={{paddingLeft:10}}>
           
       
          <Button  className="btn btn-outline-dark" 
             
             onClick={(OutlineTypesExample) =>
               window.open("https://elchefe.bemp.online/")
             }
             variant="container"
             size="large"
             style={{
              color: "white",
              background: "linear-gradient(327deg, rgba(255,168,0,1) 10%, rgba(255,175,0,1) 23%, rgba(255,105,0,1) 89%)",
              borderRadius: 50,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 10,
              paddingBottom: 10,
              fontSize: 20,
            }}
           >
             Agendar Agora
           </Button>
          
        </CardActions>
      </Card>
      <Grid container>
        <Grid item xs={8} sm={8} md={8} lg={6}>
          <div
            style={{
              background: "linear-gradient(327deg, rgba(255,188,0,1) 26%, rgba(255,169,0,1) 46%, rgba(255,140,0,1) 80%)",
              borderRadius: 0,
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 20,
            }}
          >
            <Typography
              fontSize={{
                lg: 75,
                md: 40,
                sm: 35,
                xs: 20,
              }}
              style={{
                fontFamily: "Helvetica",
                lineHeight: "1em",
                fontWeight: "bold",
                color: "black",
              }}
              gutterBottom
            >
              Agende agora pelo <br />
               Nosso<br />
              App.
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                
                width: "80%",
              }}
            >
              <Button color="" className="btn btn-dark"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/no/app/el-chefe/id1635634910",
                    "_blank"
                  )
                }
                variant="container"
                size="large"
                style={{
                 
                  marginLeft:0,
                  padding:10,
                  width: 200,
                  borderRadius:10,
                  fontWeight: 700,
                }}
              >
                IOS
              </Button>
              <Button  color="" className="btn btn-dark"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=br.com.salaozen.elchefe",
                    "_blank"
                  )
                }
                variant="container" 
                size="large"
                style={{
                  borderRadius:10,
                  
                  width: 200,
                  fontWeight: 700,

                }}
              >
                ANDROID
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} style={{ flex: 1 }}>
          <img
            alt="mao_celular"
            src={maoCelular}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
      <div style={{ backgroundColor: "#211F20" }}>
        <ListTable list={servicos} title="Tabela de Preços" />
       
        <ListTable list={vantagens} title="Planos do Clube do Chefe" />
      </div>
    </React.Fragment>
  );
};

const ListTable = ({ list = [], title }) => {
  return (
    <>
      <div style={{ paddingTop: 50, paddingRight: 50, paddingLeft: 50 }}>
        <Typography
          fontSize={{
            lg: 40,
            md: 35,
            sm: 30,
            xs: 25,
          }}
          style={{
            fontFamily: "Helvetica",
            lineHeight: "1em",
            fontWeight: "bold",
            color: "#F8F4F3",
          }}
          gutterBottom
        >
          {title}
        </Typography>
        <Divider
          style={{
            backgroundColor: "#F3B627",
            width: "39%",
            borderBottomWidth: 5,
          }}
        />
        <Divider style={{ backgroundColor: "#F3B627" }} />
      </div>
      <List sx={{ width: "100%", padding: 10 }}>
        {list.map((servico) => (
          <>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Typography
                  style={{
                    fontFamily: "Helvetica",
                    lineHeight: "1em",
                    color: "#F8F4F3",
                  }}
                  padding={2}
                  gutterBottom
                >
                  {servico.nome}
                </Typography>
              </Grid>
              {servico.preco && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography
                    style={{
                      fontFamily: "Helvetica",
                      lineHeight: "1em",
                      fontWeight: "bold",
                      color: "#F8F4F3",
                    }}
                    padding={2}
                    gutterBottom
                  >
                    {servico.preco}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Divider style={{ backgroundColor: "#F3B627" }} />
          </>
        ))}
      </List>
    </>
  );
};


export default Home;

const servicos = [
  { nome: "Luzes ou Platinado", preco: "R$ 190,00" },
  { nome: "Combo + Selagem", preco: "R$ 180,00 (a partir de)" },
  { nome: "Selagem + Corte de Cabelo", preco: "R$ 130,00 (a partir de)" },
  { nome: "Corte de Cabelo Pai + Filho", preco: "R$ 90,00" },
  { nome: "Selagem", preco: "R$ 90,00 (a partir de)" },
  { nome: "Cabelo + Barba", preco: "R$ 85,00" },
  { nome: "Tintura de Cabelo", preco: "R$ 60,00 (a partir de)" },
  { nome: "Corte de Cabelo", preco: "R$ 50,00" },
  { nome: "Barba", preco: "R$ 45,00" },
  { nome: "Higienização Facial", preco: "R$ 45,00" },
  { nome: "Pigmentação Barba", preco: "R$ 35,00" },
  { nome: "Hidratação Cabelo", preco: "R$ 35,00" },
  { nome: "Design de Sobrancelhas", preco: "R$ 25,00" },
  { nome: "Depilação Nariz ou Orelha Cera", preco: "R$ 20,00" },
  { nome: "Acabamento Barba", preco: "R$ 20,00" },
  { nome: "Lavagem e Penteado", preco: "R$ 20,00" },
  { nome: "Pezinho Cabelo", preco: "R$ 20,00" },
  { nome: "Máscara Black Facial", preco: "R$ 15,00" },
];
const servicoManicure = [
  { nome: "Pé", preco:"R$ 25,00"},
  { nome: "Mão",preco:"R$ 25,00"},
  { nome: "Pé e Mão",preco:"R$ 45,00"}
];
const vantagens = [
  { nome: "Plano de Corte de cabelo Ilimitado", preco: "R$ 89,90"},
  
  { nome: "Plano de Cabelo + barba Ilimitados", preco: "R$ 159,90" },
  { nome: "Plano de Barba Ilimitado", preco: "R$ 119,90" },
  {
    nome: "Desconto de 10% em outros serviços e em qualquer produto",
  },
];

